import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '@backend/src/trpc/routers/index'
import superjson from 'superjson'
import type { Ref } from 'vue';
import { watch, ref } from 'vue';
import { useAclStore } from '../store/acl';

export const useTRPCClient = (): { $trpcClient:ReturnType<typeof createTRPCProxyClient<AppRouter>>, $trpcAuthenticated: Ref<boolean> } => {
  console.log('window.location.hostname', window.location.hostname);
  // const useURI = typeof window !== 'undefined' &&
    // window.location.hostname !== 'localhost';
  const useURI = false;
  
  const trpcUrl = (() => {
    if (window.location.hostname === 'localhost' || window.location.hostname === 'cel.local') {
      return 'http://localhost:3910/trpc';
    }
    return siteIsCelelements() 
      ? 'https://api3.cel-elements.com/trpc'
      : 'https://pizza.mercstudio.com/trpc';
  })()

  // store is initially undefined
  const store = useAclStore()
  // const portSuffix = window.location.hostname === 'localhost' ? ':4000' : '';
  
  console.log('trpcHost', {trpcUrl});
  const $trpcAuthenticated = ref(false);
  const loadClient = () => {
    return createTRPCProxyClient<AppRouter>({links: [
      httpBatchLink({
        url: trpcUrl,
        // fetch(url, options) {
        //   return fetch(url, {
        //     ...options,
        //     // credentials: 'include',
        //   });
        // },
        headers: {
          ...(store?.jwt ? { 'authorization': `Bearer ${store?.jwt}` } : {}),
        }
      }),
    ],
      transformer: superjson,
    })
  }
  let $client = loadClient();
  watch(() => store?.currentUser, (u) => {
    $client = loadClient();
    if (u) {
      $trpcAuthenticated.value = true;
    } else {
      $trpcAuthenticated.value = false;
    }
  });
  return {
    $trpcClient: $client,
    $trpcAuthenticated,
  };
}
