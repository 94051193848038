import { acceptHMRUpdate, defineStore } from 'pinia';
import * as Realm from 'realm-web';
import type { CartType } from '../models/cart';
import type { ProductVariantType } from '../models/productVariant';
import type { UserType } from '../models/user';
import { callFunc } from '../realm/data/call';
import { getRealm } from '~/realm/setup';
import { useTRPCAsync } from '../composable/trpc';
// import { dataFetchSingle, dataFetchSingleWhere } from '../realm/data/get'

function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; Max-Age=-99999999; path=/`;
  }
}

export interface UserSession {
  id: string;
  uid: string;
  username?: string;
  accessToken: string | null;
  refreshToken: string | null;
  avatar?: string;
  teams: string[];
  customData: UserType;
  providerType: string;
  cartSessionToken?: string | null;
  jwt?: string | null;
}

const defaultCart = (): CartType => {
  return {
    v: 0,
    amount: 0,
    items: [],
    payments: [],
    voucherAmount: 0,
    shippingVoucherAmount: 0,
    shippingAmount: 0,
    taxAmount: 0,
    orderTotal: 0,
  } as CartType;
};

const guestUser = () => {
  return {
    id: '',
    uid: '',
    accessToken: null,
    refreshToken: null,
    avatar: undefined,
    teams: [],
    customData: {
      firstName: '',
      lastName: '',
      email: '',
      status: 0,
      uid: '',
    },
    providerType: '',
    cartSessionToken: '',
  };
};

export const useAclStore = defineStore('acl', {
  state: (): {
    loading: boolean;
    currentUser: UserSession;
    loginError: string;
    cartSessionToken: string;
    cart: CartType;
    cartVariants: ProductVariantType[];
    jwt: string;
  } => {
    return {
      loading: false,
      currentUser: {} as UserSession,
      loginError: '' as string,
      cartSessionToken: '' as string,
      cart: defaultCart(),
      cartVariants: [] as ProductVariantType[],
      jwt: '',
    };
  },

  getters: {
    currentUserName: (state) => {
      const data = state.currentUser?.customData;
      console.log('currentUserName', state.currentUser, data);
      if (state.currentUser && data) {
        if (data.firstName && data.lastName) {
          return `${data.firstName} ${data.lastName}`;
        }
        return data.firstName;
      }
      if (data?.email) {
        return data.email;
      }
      return 'Guest';
    },
    siteCurrencyLabel: () => {
      // TODO
      return 'RM';
      // if (state.currentUser && state.currentUser.customData) {
      //   return state.currentUser.customData?.currency
      // }
      // return 'USD'
    },
  },
  actions: {
    async logout() {
      this.loginError = '';
      this.loading = true;
      const realm = getRealm();
      try {
        await realm.currentUser?.logOut();
      } catch (err) {
        console.log('logout failed', err);
      } finally {
        console.log('logout');
        window.localStorage.clear();
        clearAllCookies();
        await realm.logIn(Realm.Credentials.anonymous());
        this.cart = defaultCart();
        this.currentUser = guestUser();
        this.loading = true;
        this.cartSessionToken = '';
        this.jwt = '';
        document.location.reload();
      }
    },

    async checkSession() {
      try {
        await this.refetchCurrentUser();
      } catch(err) {
        // ignore
      }
      const realm = getRealm();
      // console.log('checksession', realm.currentUser, this.currentUser.uid)
      if (!realm.currentUser && (this.currentUser?.accessToken || this.currentUser.uid === realm.currentUser?.id)) {
        console.log('user session expired', realm.currentUser?.id, this.currentUser.uid);
        this.logout();
        return false;
      }
      if (!realm.currentUser) {
        await realm.logIn(Realm.Credentials.anonymous());
      }
      return true;
    },
    clearCart() {
      this.cart = defaultCart();
      // if (cart._ownerId !== uid) {
      //   const cartOwner = await db.collection('users').findOne({uid: cart._ownerId});
      //   if (cartOwner) {
      //     console.log('found owner', JSON.stringify({email: cartOwner.email, uid: cartOwner.uid}), cart._ownerId);
      //     throw new Error('Sorry cart does not belong to you');  
      //   }
      // }

      return callFunc('clearCart', this.cartSessionToken);
    },
    async setCartSession(token: string) {
      this.cartSessionToken = token;
      this.currentUser.cartSessionToken = token;
    },

    async refetchCurrentUser() {
      const realm = getRealm();
      if (!realm.currentUser) {
        return;
      }
      // const user = await dataFetchSingleWhere<UserType>(userModel, { uid: realm.currentUser.id })
      // if (!user) {
      //   console.error('user missing', realm.currentUser.id)
      //   throw new Error('User not found')
      // }
      // console.log('refetchCurrentUser', user)
      await realm.currentUser.refreshCustomData();
      this.currentUser.id = realm.currentUser.id;
      this.currentUser.customData = realm.currentUser.customData;
      this.currentUser.teams = realm.currentUser.customData?.teams;
      this.currentUser.providerType = realm.currentUser.providerType;
      this.cartSessionToken = realm.currentUser.customData?.cartSessionToken;

      if (!this.jwt) {
        const { execute: checkAuth } = useTRPCAsync((client) => {
          return client.me.checkAuth.mutate
        });
        if (!this.currentUser.customData.jwt1) {
          console.error('user missing jwt1', this.currentUser.customData);
          throw new Error('User missing jwt1');
        }
        console.log('refetchUser customData', this.currentUser.customData.jwt1);
        const res = await checkAuth({ token: this.currentUser.customData.jwt1 as string });
        console.log('checkAuth', res);
        this.jwt = res.token;
      }

      console.log('refetchUser dob', this.currentUser.customData, this.currentUser.customData.dob);
      if (this.currentUser.customData?.dob) {
        this.currentUser.customData.dob = new Date(this.currentUser.customData.dob?.$date?.$numberLong);
      }
    },
    async setTeams(teams: string[]) {
      this.currentUser.teams = teams;
    },
    async loadCart(sessionToken?: string): Promise<{ cart: CartType; cartVariants: ProductVariantType[] } | null> {
      // if sessionToken === undefined means we are loading the cart from the current user
      console.log('loadcart', sessionToken ? this.cartSessionToken : sessionToken);
      const res: { cart: CartType; variants?: ProductVariantType } | undefined = await callFunc('getCart2', {
        sessionToken: !sessionToken ? this.cartSessionToken : sessionToken,
      });
      console.log('loadcart cart?', res);
      if (!res) {
        return null;
      }

      this.cart = res.cart;
      this.cartVariants = res.variants || [];

      if (this.cartSessionToken !== res.cart.sessionToken) {
        await this.setCartSession(res.cart.sessionToken ?? '');
      }

      return { cart: this.cart, cartVariants: this.cartVariants };
    },
    async loginEmail(email: string, pass: string) {
      this.loginError = '';
      this.loading = true;
      const { execute: checkAuth } = useTRPCAsync((client) => {
        return client.me.checkAuth.mutate
      });
      
      try {
        const realm = getRealm();
        // const credentials = Realm.Credentials.function({ username: email, password: pass })
        const credentials = Realm.Credentials.emailPassword(email, pass);
        const res = await realm.logIn(credentials);
        const currentUser = realm.currentUser;

        
        console.log('loggedin?', currentUser.customData);
        this.currentUser = {
          id: res.id,
          uid: res.id,
          username: res.profile.name,
          avatar: res.profile.pictureUrl,
          accessToken: res.accessToken,
          refreshToken: res.refreshToken,
          teams: currentUser?.customData?.teams,
          customData: currentUser?.customData,
          providerType: currentUser?.providerType,
        };

        if (this.currentUser.customData?.dob) {
          console.log('refetchUser2 dob', this.currentUser.customData.dob);
          this.currentUser.customData.dob = new Date(this.currentUser.customData.dob?.$date?.$numberLong);
        }
        setTimeout(() => {
          this.refetchCurrentUser();
        }, 1000);
        
        return this.currentUser;
      } catch (err) {
        console.log('login failed', err);
      } finally {
        this.loading = true;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async sendVerifyEmail(_email: string) {
      // this.loading = true
      // try {
      // }
      // finally {
      //   this.loading = false
      // }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAclStore, import.meta.hot));
