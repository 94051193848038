import { inferRouterProxyClient, TRPCClientError } from '@trpc/client'
import { useStore } from 'vuex';
import type { AppRouter } from '@backend/src/trpc/routers/index'
import { useTRPCClient } from './trpc-client'

type defaultQueryType<TReturn> = {
  enabled?: boolean;
  authenticated?: boolean;
  onQuery?: (query: TReturn) => void;
  onError?: (err: Error) => void;
};

const defaultQueryOptions = {
  enabled: true,
  authenticated: true,
  onQuery: () => { },
  onError: () => { },
}

type TRPCOption = {
  authenticate?: boolean;
}

// query and automatic requery 
export const useTRPCQuery = <TReturn>(
  callback: (client: inferRouterProxyClient<AppRouter>) => Promise<TReturn>,
  deps: any[] = [],
  options: defaultQueryType<TReturn> = {}) => {
  const prefixRandom = Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
  const optionsWithDefaults = { ...defaultQueryOptions, ...options }
  const { enabled, authenticated, onQuery, onError } = optionsWithDefaults

  const { $trpcClient, $trpcAuthenticated } = useTRPCClient()
  const data = ref<TReturn>()
  const loading = ref(false)
  const status = ref(0)
  const error = ref('')
  // console.log('query', enabled)
  const execute = async (): Promise<TReturn> => {
    // console.log('execute', loading.value)
    if (loading.value) {
      throw new Error('Already running')
    }
    loading.value = true
    console.log(prefixRandom, 'trpc exec')
    const res = await callback($trpcClient).catch((err: any) => {
      // console.log('trpc err', err)
      if (err instanceof TRPCClientError) {
        const errTRPC = err as TRPCClientError<AppRouter>
        console.log(prefixRandom, 'trpc err', err)
        error.value = (errTRPC.data?.path ? `${errTRPC.data.path}: ` : '') + err.message
      } else {
        error.value = err.message
      }
      
      onError(err)
      loading.value = false
      throw err
      // return undefined
    })
    loading.value = false
    data.value = res

    onQuery(res)
    return res
  }

  watch([$trpcAuthenticated, enabled, ...deps], () => {
    // console.log('watch', prefixRandom, enabled, deps)
    if (enabled && (!authenticated || (authenticated && $trpcAuthenticated.value))) {
      console.log(prefixRandom, 'running', enabled, $trpcAuthenticated.value)
      if (loading.value) {
        return
      }
      execute()
    }
  }, { immediate: true })

  return { data, loading, status, error, execute }
}

// with manual execution
export function useTRPCAsync<T, InputT>(query: (
  client: inferRouterProxyClient<AppRouter>,
  ) => (input: InputT) => Promise<T>,
  options : TRPCOption = { authenticate: true },
  ) {
  const prefixRandom = Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
  const { $trpcClient, $trpcAuthenticated } = useTRPCClient()
  const data = ref<T>()
  const loading = ref(false)
  const status = ref(0)
  const error = ref('')

  const execute = async (input: InputT) => {
    loading.value = true
    const res = await query($trpcClient)(input).catch((err: any) => {
      // console.log('trpc err', err)
      if (err instanceof TRPCClientError) {
        const errTRPC = err as TRPCClientError<AppRouter>
        console.log(prefixRandom, 'trpc err2', err)
        error.value = (errTRPC.data?.path ? `${errTRPC.data.path}: ` : '') + err.message
      } else {
        error.value = err.message
      }
      
      // onError(err)
      loading.value = false
      throw err
      // return undefined
    })
    loading.value = false
    data.value = res
    return res
  }

  return { data, loading, status, error, authenticated: $trpcAuthenticated, execute }
}

// export function useTRPCMutation<T, TBody>(query: (
//   client: inferRouterProxyClient<AppRouter>, mutataData?: Partial<TBody>) => Promise<T>,
//   ) {
//   const { client } = useTRPCContext();
//   const [data, setData] = useState<T>(); // {} to avoid pre-call data error
//   const [error, setError] = useState<string>('');
//   const [status, setStatus] = useState<Status>(Status.Initial);
//   const [isLoading, setLoading] = useState<boolean>(false);
//   // const mutexWithTimeout = withTimeout(new Mutex(), 15000);

//   const mutate = async function (mutateData?: Partial<TBody>): Promise<T | null> {
//     setLoading(true);
//     setStatus(Status.Pending);
//     setError('');
//     setData(undefined);
//     return query(client, mutateData).then((res) => {
//       // console.log('query', tPath, res);
//       setData(res);
//       setLoading(false);
//       setStatus(Status.Success);
//       return res;
//     }).catch((err: any) => {
//       setError(err.message);
//       setStatus(Status.Failed);
//       return null;
//     }).finally(() => {
//       setLoading(false);
//     });
//   };
//   return {
//     data, status, error, isLoading, client,
//     mutate,
//   };
// }

// export function useTRPCAsync2<T, InputT>(query: (
//   client: inferRouterProxyClient<AppRouter>,
//   ) => (input: InputT) => Promise<T>,
//   ) {
//   const { client } = useTRPCContext();
//   const [data, setData] = useState<T>(); // {} to avoid pre-call data error
//   const [error, setError] = useState<string>('');
//   const [status, setStatus] = useState<Status>(Status.Initial);
//   const [isLoading, setLoading] = useState<boolean>(false);
//   // const mutexWithTimeout = withTimeout(new Mutex(), 15000);

//   const mutate = async function (input: InputT): Promise<T | null> {
//     setLoading(true);
//     setStatus(Status.Pending);
//     setError('');
//     setData(undefined);
//     return query(client)(input).then((res) => {
//       // console.log('query', tPath, res);
//       setData(res);
//       setLoading(false);
//       setStatus(Status.Success);
//       return res;
//     }).catch((err: any) => {
//       setError(err.message);
//       setStatus(Status.Failed);
//       return null;
//     }).finally(() => {
//       setLoading(false);
//     });
//   };
//   return {
//     data, status, error, isLoading, client,
//     mutate,
//   };
// }

// export const useAsyncClient = async (query: AsyncClientQuery, { authorization = true }) => {
//   const { client } = useClient()
//   const { pending: isLoading, data } = useLazyAsyncData('count', () => $fetch('/api/count'))

//   return { isLoading, data }
// }
